import styled from "styled-components"
import media from "styled-media-query"
import AnimacaoSite from "../../../images/gif/AnimacaoSite.gif"
import BackgroundImage from "gatsby-background-image"

export const Container = styled.div`
  display: flex;
  min-height: 100%;
  align-items: flex-end;
  flex-direction: column;
  position: relative;
  min-height: 667px;
  overflow: hidden;
  flex: 1;
`
export const ClearFix = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  width: 100%;
  & > div {
    flex: 1;
    height: 100%;
    ${media.lessThan("1000px")`
      display: none;
    `}
  }
  ${media.greaterThan("800px")`
    min-height: 700px;
  `}
`
export const Content = styled.section`
  display: flex;
  flex-direction: column;
  flex: 2;
  width: 100%;
  justify-content: center;
  ::before {
    content: "";
    width: 100%;
    height: 75%;
    position: absolute;
    left: 0;
    z-index: -1;
    background-image: url(${AnimacaoSite});
    background-repeat: no-repeat;
    background-position: 0 70%;
    background-size: cover;
  }

  ${media.lessThan("1000px")`
    ::before{
      display: none;
    }
    max-width: 100%;
    padding: 2rem;
  `}
`

export const Title = styled.h1`
  font-size: 3.3rem;
  font-weight: bold;
  line-height: 1.5;
  text-align: left;
  color: #002a6a;
  max-width: 63rem;
  width: 100%;

  p {
    max-width: 51rem;
    width: 100%;
    font-size: 1.8rem;
    line-height: 1.33;
    text-align: left;
    color: #002a6a;
    font-weight: normal;
    margin-top: 3rem;
  }
  margin-bottom: 5rem;

  ${media.lessThan("1280px")`
    line-height: 1.2;
    font-size: 3rem;
    p{
      font-size: 1.5rem;
    }
  `}
  ${media.lessThan("1100px")`
    max-width: 100%;
  `}
`

export const FeaturesList = styled.ul`
  list-style: none;
  display: flex;
  justify-content: flex-start;
  width: 100%;
  overflow: hidden;
  height: 100%;

  li {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 160px;
    width: 100%;

    img {
      width: 64px;
      height: auto;
      margin-bottom: 10px;
    }

    p {
      font-size: 1.6rem;
      line-height: 1.38;
      text-align: center;
      color: #002a6a;
    }
    & + li {
      margin-left: 7rem;
    }
  }

  ${media.lessThan("1280px")`
    p{
      font-size: 1.5rem;
    }
    li{
      & + li {
        margin-left: 3.5rem;
      }
      p{
        font-size: 1.4rem;
      }
    }
  `}

  ${media.lessThan("800px")`
    display: flex;
    justify-content: center;
    li{
      position: relative;
      display:none;
      transition: 0.75s linear;
      max-height: 150px;
      width: 160px;
      overflow: hidden;

      &.page1{
        display: flex;
        width: 100%;
      }
      &.page2{
        display: flex;
        width: 100%;
      }
      &.show{
        right: 0;
      }
      &.hide{
        right: -200%;
        width: 0;
        margin-left: 0rem;
      }
    }
  `}
`

export const Bullet = styled.ul`
  list-style: none;
  display: none;
  margin: auto;
  margin-top: 38px;

  & li {
    width: 40px;
    height: 10px;
    border-radius: 100px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border: solid 0.8px #ffffff;
    background-color: #00b1b1;
    cursor: pointer;
    transition: 0.75s ease-in-out;
    opacity: 0.2;

    & + li {
      margin-left: 20px;
    }

    &.active {
      opacity: 1;
    }
  }
  ${media.lessThan("800px")`
    display: flex;
  `}
`
export const Carousel = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`

export const Footer = styled(BackgroundImage).attrs({
  tag: "footer",
  background: "#ff9d00",
})`
  width: 100%;
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  min-height: 327px;
  max-height: 327px;
  align-items: center;
  padding: 2rem 19rem;

  h2 {
    display: block;
    max-width: 600px;
    font-size: 2rem;
    font-weight: bold;
    text-align: left;
    color: #ffffff;
    max-height: 4.8em;
    line-height: 1.6;
    hr {
      width: 90px;
      height: 6px;
      border-radius: 100px;
      background-color: #ffffff;
      margin: 1.5rem 0;
    }
  }
  ${media.lessThan("1280px")`
    max-height: 250px;
    min-height: 250px;
  `}
  ${media.lessThan("920px")`
    padding: 2rem 10rem;
  `}
  ${media.lessThan("690px")`
    padding: 2rem 8rem;
  `}
  ${media.lessThan("600px")`
    padding: 2rem;
  `}
`
