import Loyalty from "../../../images/svg/icone-loyalty.svg"
import UserExperience from "../../../images/svg/icone-user-experience.svg"
import OpenBanking from "../../../images/svg/icone-open-banking.svg"
import Dashboard from "../../../images/svg/icone-dashboards.svg"
import Lending from "../../../images/svg/icone-lending.svg"

import LoyaltyWhite from "../../../images/svg/icone-loyalty-white.svg"
import UserExperienceWhite from "../../../images/svg/icone-user-experience-white.svg"
import OpenBankingWhite from "../../../images/svg/icone-open-banking-white.svg"
import DashboardWhite from "../../../images/svg/icone-dashboards-white.svg"
import LendingWhite from "../../../images/svg/icone-lending-white.svg"

export default [
  {
    icon: Loyalty,
    iconWhite: LoyaltyWhite,
    text: "Loyalty",
    content:
      ["Transformamos o conceito de fidelização e ações de ativação ao disponibilizar uma solução que aplica ações promocionais a partir do comportamento de uso. Podem estabelecer perfis de comportamento ou selecionar grupo e aplicar campanhas de cashback, condições de tarifas promocionais ou gerar alertas automáticos.",
       "We transformed the concept of loyalty and activation actions by providing a solution that applies promotional actions based on usage behavior. They can establish behavior profiles or select groups and apply cashback campaigns, promotional rate conditions or generate automatic alerts.",
       "Transformamos el concepto de fidelización y acciones de activación al proporcionar una solución que aplica acciones promocionales basadas en el comportamiento de uso. Pueden establecer perfiles de comportamiento o seleccionar grupos y aplicar campañas de cashback, condiciones de tarifas promocionales o generar alertas automáticas."],
  },
  {
    icon: UserExperience,
    iconWhite: UserExperienceWhite,
    text: "User Experience",
    content:
      ["Na Hub Fintech, as parametrizações podem ser realizadas no nível portador. Assim, a solução torna-se customizável e aderente às regras do seu produto. O conceito também é aplicado no desenho da arquitetura sistêmica, onde todas as APIs contam com recebimento de informações Webhook para notificar a conclusão de atividades. E ainda compartilhamos melhores experiências de todas as nossas soluções, através de um App White Label. A sua empresa pode contar com referência na construção das seções financeiras (modelos de integração API).",
       "At Hub all functionalities can be parameterized by end user level, including fees, welcome kit, cards, apps and authorization rules. The flexibility is also applied in the API design with all the endpoints with webhook confirmation. So our solutions became more flexible and in pace with your business. At our whitelabel app your company can count all Hub services and same u/x flexibility. With us your company access the best in class solution in financial services",
       "En Hub Fintech, la parametrización se puede llevar a cabo a nivel de operador. Por lo tanto, la solución se vuelve personalizable y se adhiere a las reglas de su producto. El concepto también se aplica en el diseño de la arquitectura sistémica, donde todas las API reciben información de Webhook para notificar la finalización de las actividades. Y aún compartimos las mejores experiencias de todas nuestras soluciones, a través de una aplicación de marca blanca. Su empresa puede contar con referencias en la construcción de las soluciones financieras (modelos de integración API)."],
  },
  {
    icon: OpenBanking,
    iconWhite: OpenBankingWhite,
    text: "Open Banking",
    content:
      ["Estrutura para a formação de qualquer empresa que queira ter seu próprio banco, através do consumo de endpoints da Hub Fintech, para compor a oferta de valor da sua conta digital, com canais totalmente White Label.",
       "Structure for the formation of any company that wants to have its own bank, through the consumption of Hub Fintech endpoints, to compose the value offer of its digital account, with totally White Label channels",
       "Estructura para la formación de cualquier empresa que quiera tener su propio banco, a través del consumo de endpoints de Hub Fintech, para componer la oferta de valor de su cuenta digital, con canales totalmente White Label"],
  },
  {
    icon: Dashboard,
    iconWhite: DashboardWhite,
    text: "Dashboard",
    content:
      ["Somos transparentes e queremos que a sua empresa tenha todas as informações em tempo real. Disponibilizamos dashboards com a performance dos principais serviços como autorização, performance de API e KPIs para gestão das atividades. A sua empresa também conta com dashboards operacionais para manter gestão de indicadores como atendimento, entrega de cartões e backoffice.",
       "We are transparent and we want your company to have all the information in real time. We provide dashboards with the performance of main services such as authorization, API performance and KPIs for managing activities. Your company also counts on operational dashboards to maintain management of indicators such as customer service, card delivery and back office.",
       "Somos transparentes y queremos que su empresa tenga toda la información en tiempo real. Proporcionamos dashboards con el desempeño de los principales servicios, como la autorización, el rendimiento de API y los KPI para gestión de las actividades. Su empresa también tiene dashboards operativos para mantener la gestión de indicadores como atención, entrega de tarjetas y backoffice."],
  },
  {
    icon: Lending,
    iconWhite: LendingWhite,
    text: "Lending",
    content:
      ["Conecte os seus clientes ao nosso Lending as a Service e ofereça ofertas de empréstimos exclusivas. Por meio da nossa avançada tecnologia de processamento com conexão via API, oferecemos uma solução de empréstimo de ponta a ponta para que o seu negócio ganhe ainda mais.",
       "Connect your customers to our Lending as a Service solution and have exclusive loan offers. Through our advanced processing technology with API connection via API, we offer an end-to-end loan solution for your business to earn even more.",
       "Conecte a sus clientes con nuestro Préstamo como Servicio y tenga ofertas exclusivas de préstamos. A través de nuestra tecnología de procesamiento avanzada con conexión a través de API, ofrecemos una solución de préstamo de extremo a extremo para que su negocio gane aún más."],
  },
]
