import styled from "styled-components"
import media from "styled-media-query"

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 6.2rem 20rem;
  position: relative;
  overflow: hidden;

  ${media.lessThan("1280px")`
    padding: 3rem 6rem;
  `}
  ${media.lessThan("600px")`
    padding: 3rem ;
  `}

  ::before {
    content: "";
    position: absolute;
    bottom: 2px;
    left: 0;
    width: 647px;
    max-width: 100%;
    height: 7px;
    border-radius: 200px;
    background-color: #00b1b1;
  }

  ${media.greaterThan("900px")`
      .scrollable{
        display: none;
      }
  `}
  ${media.lessThan("901px")`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    background-color: #fff;
    transition: background-color 1s linear;
  `}
`
export const Title = styled.h1`
  font-size: 4.4rem;
  font-weight: bold;
  line-height: 1.36;
  text-align: left;
  color: #002a6a;
  width: 100%;
  display: flex;
  align-items: center;

  hr {
    width: 6px;
    height: 55px;
    border-radius: 100px;
    background-color: #002a6a;
    margin: 30px;
  }
  p {
    width: 100%;
    max-width: 438px;
    font-size: 2rem;
    line-height: 1.7;
    text-align: left;
    color: #707070;
    font-weight: normal;
  }
  ${media.lessThan("1280px")`
    font-size: 3.6rem;
    p{
      font-size: 1.6rem;
    }
  `}
  ${media.lessThan("925px")`
    flex-direction: column;
    hr{
      display: none;
    }    
    p{
      max-width: 100%;
      text-align: center;
      font-size: 1.4rem;
    }
  `}
  ${media.lessThan("600px")`
    text-align: center;
  `}
`
export const Content = styled.div`
  display: flex;
  min-height: 640px;
  padding-left: 8rem;
  flex: 1;

  ${media.lessThan("900px")`
    display: none;
    flex-direction: column;
    padding-left: 0;
    align-items:center;
  `}
  ${media.greaterThan("899px")`
    padding-left: 0;
    align-items:center;
  `}
`

export const ListServices = styled.ul`
  display: flex;
  max-width: 400px;
  min-width: 400px;
  flex-wrap: wrap;
  margin-top: 2rem;
  width: 100%;
  justify-content: flex-start;
  ${media.lessThan("600px")`
    max-width: 300px;
  `}
`

export const ServiceItem = styled.li`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 174px;
  max-height: 172px;
  min-width: 174px;
  min-height: 172px;
  border-radius: 20px;
  padding: 2rem;
  font-size: 2rem;
  line-height: 1.75;
  text-align: center;
  color: #fff;
  box-shadow: 0 0 18px 0 rgba(0, 177, 177, 0.2);
  transition: 0.75s linear;

  & :nth-child(even) {
    margin-top: 40px;
    margin-left: 40px;
    ${media.lessThan("900px")`
      margin-top:0px;
      margin-left:40px;
    `}
  }

  ${media.lessThan("900px")`
    & + li {
      margin-left: 40px;
    }
  `}

  & img {
    width: auto;
    min-height: 45px;
  }
  & :hover {
    box-shadow: 0 14px 20px 0 rgba(0, 177, 177, 0.4);
    cursor: pointer;
  }
  &.active {
    background-image: linear-gradient(to bottom, #00b1b1, #002a6a);
    background-color: ${props => props.bg};
    box-shadow: 0 14px 20px 0 rgba(0, 177, 177, 0.4);
    p {
      color: #ffffff;
    }
  }
  p {
    font-size: 1.8rem;
    text-align: center;
    color: #00b1b1;
    margin-top: 10px;
  }
  ${media.lessThan("1280px")`
    max-width: 164px;
    max-height: 162px;
    min-width: 164px;
    min-height: 162px;
    & img{
      min-width: 40px;
    }
    & p{
      font-size: 1.6rem;
    }
  `}
  ${media.lessThan("600px")`
    max-width: 124px;
    max-height: 114px;
    min-width: 124px;
    min-height: 114px;

    & img {
      width: auto;
      height: 30px;

      & + p {
        margin-top: 15px;
      }
    }
    & p{
      line-height: 1;
    }
  `}
`
export const Description = styled.div`
  display: flex;
  padding: 2rem 0 2rem 8rem;
  flex-direction: column;
  min-height: 465px;
  h1 {
    font-size: 2.8rem;
    font-weight: bold;
    line-height: 2.4;
    text-align: left;
    color: #00b1b1;
  }
  p {
    font-size: 2rem;
    line-height: 1.88;
    text-align: left;
    color: #7c7c7c;
    flex: 1;
  }
  & > div {
    align-self: flex-end;
    justify-self: flex-end;
    display: flex;
    align-items: center;
    margin-top: 2rem;
    cursor: pointer;

    p {
      margin-left: 15px;
      font-size: 2.5rem;
      line-height: 1.36;
      text-align: right;
      color: #00b1b1;
      text-transform: uppercase;
    }
    & :active {
      opacity: 0.5;
    }
  }
  ${media.lessThan("1280px")`
  & h1 {
    font-size: 2.4rem;
  }
    & p{
      font-size: 1.6rem;
    }
  `}
  ${media.lessThan("900px")`
    margin-top: 0;
    padding: 2rem;
  `}
`

export const CustomScrollbar = styled.div`
  width: 100%;
  float: left;
  height: 180px;
  margin-left: 15px;
  margin-top: 40px;
  overflow-x: scroll;
  overflow-y: hidden;
  background: transparent;
  -webkit-overflow-scrolling: touch;

  ${media.lessThan("601px")`
    height: 140px;
  `}
  &::-webkit-scrollbar {
    height: 4px;
    border-radius: 50px;
    background-color: #fff;
  }
  &::-webkit-scrollbar-thumb {
    cursor: pointer;
    background-color: #ddd;
    transition: background 0.2s;
    &:hover {
      background-color: #bbb;
    }
  }
  &::-webkit-scrollbar-track {
    background-color: #fff;
  }
`
export const ListSolutions = styled.ul`
  list-style: none;
  display: flex;
  width: 100%;
  flex: 1;
  justify-content: flex-start;
  padding: 0 2rem;
  ${media.lessThan("1280px")`
    justify-content: space-between;
  `}
`
