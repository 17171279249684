import styled, { keyframes } from 'styled-components';
import MarcaDagua from '../../images/svg/seta-marca-dagua.svg';
import media from 'styled-media-query';
import ArrowDown from '../../images/svg/seta-home.svg';

const typing = keyframes`
  from { width: 0 };
  to { width: 100% };
`;

const blinkCaret = keyframes`
  from,to { border-color: transparent; };
  50% { border-color: white; };
`;
const pulse = keyframes`
  0%{
    top: 0px;
  }
  50%{
    top: 10px;
  }
  100%{
    top:0px;
  }
`;

export const Header = styled.div``;

export const Banner = styled.div``;
export const Flags = styled.div``;

export const Flag = styled.img``;

export const Container = styled.div`
  display: flex;
  background-image: linear-gradient(to bottom, #00b1b1 0%, #002a6a 100%);
  height: 100%;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  padding: 2rem;
  position: relative;
  overflow: hidden;
  z-index: 2;

  h1 {
    font-size: 2.8rem;
    font-weight: bold;
    line-height: 1.32;
    text-align: center;
    color: #ffffff;
    margin-top: 3rem;
    max-width: 600px;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    border-right: 5px solid;
    margin: 0 auto;
    color: #ffffff;
    animation: ${typing} 5s steps(40, end),
      ${blinkCaret} 0.75s step-end infinite;

    ${media.lessThan('600px')`
        font-size: 1.8rem;
    `}
  }

  ${Header}{
    flex-direction: column;
    display: flex;
    width: 100%;
    position: absolute;
    top: 0px;
    justify-content: space-around;

    ${Banner}{
      display: flex;
      width: 100%;
      min-height: 50px;
      background-color: #002a6a;
      position: relative;
      text-align: center;
      top: 0px;
      z-index: 1;
      justify-content: space-around;
      align-items: center;

    
      p {
        color: #ffffff;
        font-size: 1.4rem;
        text-align: center;
        line-height: 1.6;

        b {
          font-weight: bold;
          line-height: 1.6;
          text-transform: uppercase;
        }
      }
    }
    ${Flags}{
      display: flex;
      flex-direction: row;
      position: relative;
      left: 15px;
      z-index: 50;
      margin-top: 15px;
      ${Flag}{
        display: flex;
        max-height: 20px!;
        max-width: 30px;
        min-height: 20px;
        min-width: 30px;
        margin-right: 15px;
      }
    }
  }

  & > img {
    max-width: 530px;
    width: 100%;
    height: 360px;
    ${media.lessThan('600px')`
      margin-top: 70px;
    `}
  }

  & > p {
    font-size: 1.6rem;
    line-height: 1.38;
    text-align: center;
    color: #ffffff;
    /* max-width: 650px; */
    width: 100%;
    overflow: hidden;
    /* white-space: nowrap; */
    margin: 0 auto;
    /* animation: ${typing} 5s steps(40, end),
      ${blinkCaret} 0.75s step-end infinite; */

    ${media.lessThan('600px')`
        font-size: 1.4rem;
    `}
  }

  ::before {
    content: "";
    background-image: url(${MarcaDagua});
    max-height: 600px;
    height: 100%;
    width: calc(100% - 15px);
    max-width: 100vw;
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    left: 15px;
    top: 0;
    ${media.lessThan('600px')`
      width: calc(70% - 15px);
    `}
  }
  ${media.lessThan('1280px')`
  justify-content: flex-start;
      & > img:first-child{
        max-width: 400px;
      }
      h1{
        
      }
      p{
        padding-top: 3rem;
        flex: 1;
      }
  `}
`;
export const ButtonNavigate = styled.div`
  outline: none;
  max-width: 120px;
  width: 100%;
  height: 70px;
  cursor: pointer;
  transition: opacity 0.2s;
  background-image: url(${ArrowDown});
  background-size: cover;
  background-position: center center;
  animation: ${pulse} 0.75s infinite;
  position: relative;

  &:hover {
    opacity: 0.7;
  }
  ${media.lessThan('1280px')`
    max-width:90px;
  `}
`;

export const Language = styled.nav`
  ul {
    list-style: none;
    display: flex;

    li {
      transition: 0.75s linear;
      font-size: 1.8rem;
      line-height: 1.33;
      text-align: left;
      color: #002a6a;
      padding: 0px 2rem;
      cursor: pointer;
      & + li {
        border-left: 2px solid #002a6a;
      }

      &.active {
        font-weight: bold;
      }
    }
    ${media.lessThan('600px')`
      li{
         font-size: 1.6rem;
      }
    `}
  }
`;
export const ButtonLogin = styled.button`
  margin: 7.5rem 0;
  width: fit-content;
  padding: 1.5rem 3rem;
  border-radius: 200px;
  background-color: #ff9d00;
  outline: none;
  cursor: pointer;

  font-size: 1.6rem;
  font-weight: bold;
  line-height: 1.38;
  text-align: center;
  color: #ffffff;

  :active {
    opacity: 0.5;
  }

  ${media.lessThan('600px')`
    margin: 2rem 0;
    padding: 0.75rem 1.5rem;
    top: 80px;
  `}
`;

export const FooterLinks = styled.nav`
  margin: 7.5rem 0 5rem;
  ul {
    list-style: none;
    display: flex;
    flex-direction: column;

    li {
      font-size: 1.6rem;
      line-height: 1.38;
      text-align: left;
      color: #7c7c7c;
      cursor: pointer;

      & + li {
        margin-top: 2rem;
      }
      :active {
        opacity: 0.5;
      }
    }
    ${media.lessThan('600px')`
      li{
       font-size: 1.4rem;
      } 
    `}
  }
  ${media.lessThan('600px')`
    margin: 3rem 0 0;
  `}
`;
export const SocialLinks = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  p {
    font-size: 1.2rem;
    line-height: 1.86;
    letter-spacing: -0.28px;
    text-align: left;
    color: #b2b2b2;
    margin-bottom: 10px;
  }

  ul {
    display: flex;
    list-style: none;

    li {
      width: 35.2px;
      height: 35.2px;
      border: solid 2px #11294d;
      border-radius: 100px;
      display: flex;
      justify-content: center;
      align-items: center;

      & + li {
        margin-left: 10px;
      }
    }
  }
`;
