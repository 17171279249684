import React, { useRef, useCallback } from "react"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import Home from "../components/Home"
import Play from "../components/Home/Play"
import Conections from "../components/Home/Conections"
import Solutions from "../components/Home/Solutions"
import Services from "../components/Home/Services"
import Contact from "../components/Home/Contact"
import Footer from "../components/Footer"
import Chat from "../components/Chat"

export default function IndexPage() {
  const nextSection = useRef(null)
  const formRef = useRef(null)

  const scrollToBottom = () => {
    if (nextSection)
      if (nextSection.current)
        nextSection.current.scrollIntoView({ behavior: "smooth" })
  }

  const scrollToForm = () => {
    if (formRef)
      if (formRef.current)
        formRef.current.scrollIntoView({ behavior: "smooth" })
  }

  return (
    <Layout>
      <SEO title="Home" />
      <Home
        scrollToBottom={() => scrollToBottom()}
        scrollToForm={() => scrollToForm()}
      />
      <Conections 
        refConections={nextSection}/>
      <Play />
      <Solutions />
      <Services />
      <Contact formRef={formRef} />
      <Footer />
      <Chat />
    </Layout>
  )
}
