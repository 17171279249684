import React, { useContext, useState } from 'react';
import { LanguageContext } from '../../../context/LanguageContext';
import services from './content';
import {
  Container,
  Content,
  CustomScrollbar,
  Description,
  ListServices,
  ListSolutions,
  ServiceItem,
  Title,
} from './styles';

export default function Services({ Language }) {
  const { idioma } = useContext(LanguageContext);

  const [activeService, setActiveService] = useState(0);

  var title = ['Nossos Serviços', 'Our services', 'Nuestros servicios'];
  var subtitle = [
    'Nossos serviços atendem todas as nossas soluções',
    'Our services meet all of our solutions',
    'Nuestros servicios atienden todas nuestras soluciones',
  ];
  return (
    <Container>
      <Title>
        {title[idioma]}
        <hr />
        <p>{subtitle[idioma]}</p>
      </Title>
      <Content>
        <ListServices>
          {services.map(({ icon, text, iconWhite }, index) => (
            <ServiceItem
              key={text}
              className={activeService === index ? 'active' : ''}
              onClick={() => setActiveService(index)}
            >
              <img
                src={activeService === index ? iconWhite : icon}
                alt={text}
              />{' '}
              <p>{text}</p>
            </ServiceItem>
          ))}
        </ListServices>
        <Description>
          <h1>{services[activeService].text}</h1>
          <p>{services[activeService].content[idioma]}</p>
        </Description>
      </Content>
      <CustomScrollbar className="scrollable">
        <ListSolutions>
          {services.map(({ icon, text, iconWhite }, index) => {
            const ref = React.createRef();

            const handleClick = () =>
              ref.current.scrollIntoView({
                behavior: 'smooth',
                inline: 'center',
              });
            return (
              <ServiceItem
                ref={ref}
                key={text}
                className={activeService === index ? 'active' : ''}
                onClick={() => {
                  setActiveService(index);
                  handleClick();
                }}
              >
                <img
                  src={activeService === index ? iconWhite : icon}
                  alt={text}
                />{' '}
                <p>{text}</p>
              </ServiceItem>
            );
          })}
        </ListSolutions>
      </CustomScrollbar>
      <Description className="scrollable">
        <h1>{services[activeService].text}</h1>
        <p>{services[activeService].content[idioma]}</p>
      </Description>
    </Container>
  );
}
