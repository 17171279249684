import Parser from 'html-react-parser';
import React, { useContext, useState } from 'react';
import { LanguageContext } from '../../../context/LanguageContext';
import content from './content';
import {
  Container,
  Content,
  CustomScrollbar,
  ListSolutions,
  SolutionItem,
  Text,
  Title,
} from './styles';

export default function Solutions() {
  const { idioma } = useContext(LanguageContext);

  const [activeSolution, setActiveSolution] = useState(0);

  var title = ['Nossas Soluções', 'Our solutions', 'Nuestras soluciones'];
  var subtitle = [
    'Com conceito modular, você decide como quer compor o sua solução, serviços e ofertas….',
    'With a modular concept, you decide how you want to compose your solution, services and offers',
    'Con un concepto modular, usted decide cómo desea componer su solución, servicios y ofertas',
  ];

  return (
    <Container>
      <Title>
        {title[idioma]} <hr />
        <p>{subtitle[idioma]}</p>
      </Title>
      <CustomScrollbar>
        <ListSolutions>
          {content.map(({ icon, iconWhite, color, text }, index) => {
            const ref = React.createRef();

            const handleClick = () =>
              ref.current.scrollIntoView({
                behavior: 'smooth',
                inline: 'center',
              });
            return (
              <SolutionItem
                ref={ref}
                key={index}
                bg={color}
                className={activeSolution === index ? 'active' : ''}
                onClick={() => {
                  handleClick();
                  setActiveSolution(index);
                }}
              >
                {icon && (
                  <img
                    src={activeSolution === index ? iconWhite : icon}
                    alt={text}
                  />
                )}
              </SolutionItem>
            );
          })}
        </ListSolutions>
      </CustomScrollbar>
      <Content>
        <div>
          <img
            src={content[activeSolution].icon}
            alt={content[activeSolution].title}
          />
        </div>
        <div>
          <Text bg={content[activeSolution].color}>
            <h1>{content[activeSolution].title[idioma]}</h1>
            {Parser(content[activeSolution].content[idioma])}
          </Text>
        </div>
      </Content>
    </Container>
  );
}
