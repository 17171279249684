import styled, { keyframes } from "styled-components"
import MarcaDagua from "../../../images/svg/seta-marca-dagua.svg"
import media from "styled-media-query"

export const Container = styled.div`
  display: flex;
  min-height: 100%;
  align-items: center;
  flex-direction: column;
  padding: 6.2rem 25rem;
  position: relative;
  overflow: hidden;
  background-image: linear-gradient(to bottom, #00b1b1 0%, #002a6a 100%);
  transition: background-color 0.5s linear;

  ::before {
    content: "";
    background-image: url(${MarcaDagua});
    max-height: 550px;
    height: 100%;
    width: calc(100% - 15px);
    max-width: 100vw;
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    left: 15px;
    top: 0;
    ${media.lessThan("600px")`
      display: none;
    `}
  }
  & section {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 6rem 0 0;
    flex:1;
    position: relative;
    overflow: hidden;
  }

  ${media.lessThan("1375px")`
   padding: 5rem 9rem;
  `}
  ${media.lessThan("900px")`
    padding: 6rem; 
    & section{
      flex-direction: column;
      padding-bottom: 0;
    }
  `}
  

  ${media.lessThan("600px")`
    padding: 2rem;
    & section{
      flex-direction: column;
      padding: 0;
    }
  `}
`

const zoom = keyframes`
  0%{
    font-size: 2rem;
    text-shadow: 0 2px 4px rgba(0,0,0,0.26);
  }
  50%{
    font-size: 2.2rem;
    text-shadow: 0 2px 4px rgba(0,0,0,0.56);
  }
  100%{
    font-size:2rem;
    text-shadow: 0 2px 4px rgba(0,0,0,0.26);
  }`

export const ChangeIcon = styled.div`
  justify-content: center;
  align-items: center;
  position: relative;
  user-select: none;

  margin: auto;

  p {
    color: #fff;
    position: absolute;
    font-size: 2rem;
    animation: ${zoom} 0.75s infinite;
  }

  ${media.greaterThan("900px")`
    &.desktop{
      display: flex;
    }
    &.mobile{
      display: none;
    }
  `}

  ${media.lessThan("901px")`
    &.desktop{
      display: none;
    }
    &.mobile{
      display: flex;
      max-width: 360px
      cursor: pointer;
    }
  `}
`

export const Title = styled.h1`
  font-size: 2.4rem;
  font-weight: normal;
  line-height: 1.5;
  text-align: center;
  color: #ffffff;
  b {
    font-size: 2.5rem;
  }

  & p {
    font-size: 1.4rem;
    line-height: 2;
    text-align: center;
    font-weight: normal;
    color: #ffffff;
  }

  ${media.lessThan("600px")`
    margin-bottom: 50px;
    font-size: 1.8rem;
  `}
`
const pulse = keyframes`
  0%{
    max-width: 240px;
  }
  50%{
    max-width: 250px;
  }
  100%{
    max-width: 240px;;
  }
`

export const PlayAnimation = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  transition: background 0.75s linear;
  transition: width 1s linear;
  background-color: ${props => (!props.play ? "#99344C" : "transparent")};
  background-image: ${props =>
    !props.play
      ? "linear-gradient(to bottom, #00b1b1 0%, #002a6a 100%)"
      : "none"};
  width: ${props => (!props.play ? "100%" : "0px")};

  & > div {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    transition: 0.75s linear;
    position: relative;
    max-height: 320px;
    min-height: 320px;

    top: ${props => (!props.play ? 0 : "100%")};

    img {
      max-width: 300px;
      cursor: pointer;
      animation: ${pulse} 0.75s infinite;

      &:hover {
        max-width: 250px;
      }
    }
    p {
      font-size: 3.6rem;
      font-weight: 500;
      line-height: 1.56;
      text-align: center;
      color: #ffffff;
    }
    ${media.lessThan("1280px")`
      p{
        font-size: 3rem;
      }
    `}
  }
`
