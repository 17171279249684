import { graphql, useStaticQuery } from 'gatsby';
import React, { useContext, useState } from 'react';
import { LanguageContext } from '../../../context/LanguageContext';
import Api from '../../../images/svg/icone-api.svg';
import Escalavel from '../../../images/svg/icone-escalavel.svg';
import Estrutura from '../../../images/svg/icone-estrutura.svg';
import Modular from '../../../images/svg/icone-modular.svg';
import {
  Bullet,
  Carousel,
  ClearFix,
  Container,
  Content,
  FeaturesList,
  Footer,
  Title,
} from './styles';

export default function Conections({ refConections }) {
  const { idioma } = useContext(LanguageContext);

  const query = graphql`
    query {
      frase_home_desktop: file(relativePath: { eq: "tarja-frase1920px.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      frase_home_laptop: file(relativePath: { eq: "tarja-frase1440px.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1440) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      frase_home_mobile: file(relativePath: { eq: "tarja-mobile.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 768) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `;
  const {
    frase_home_desktop,
    frase_home_laptop,
    frase_home_mobile,
  } = useStaticQuery(query);

  const sources = [
    frase_home_mobile.childImageSharp.fluid,
    { ...frase_home_laptop.childImageSharp.fluid, media: `(min-width: 768px)` },
    {
      ...frase_home_desktop.childImageSharp.fluid,
      media: `(min-width: 1440px)`,
    },
  ];
  const [activePage, setActivePage] = useState(1);
  const features = [
    {
      icon: Estrutura,
      text: [
        'Conexões de serviços e operações de cash-in e out',
        'Service connections and cash-in and out transactions',
        'Conexiones de servicio y operaciones de cash-in y out',
      ],
      page: 1,
    },
    {
      icon: Modular,
      text: [
        'Arquitetura sistêmica, modular e microsserviços',
        'Systemic, modular architecture and microservices',
        'Arquitectura sistémica, modular y microservicios',
      ],
      page: 1,
    },
    {
      icon: Api,
      text: [
        'Referência em API’s',
        'A reference for APIs',
        'Referencia en API',
      ],
      page: 2,
    },
    {
      icon: Escalavel,
      text: [
        'Plataforma sistêmica e escalável',
        'Systemic and scalable platform',
        'Plataforma sistémica y escalable',
      ],
      page: 2,
    },
  ];

  var title = [
    'Integramos modelos tradicionais de pagamentos com modelos disruptivos',
    'We integrate traditional payment models with disruptive models',
    'Integramos modelos tradicionales de pago con modelos disruptivos',
  ];
  var subtitle = [
    'Ofertamos soluções modulares e somos capazes de integrar diretamente na cadeia de negócios dos nossos clientes',
    "We offer modular solutions and we are able to integrate directly into our customers' business chain",
    'Ofrecemos soluciones modulares y somos capaces de integrar directamente en la cadena comercial de nuestros clientes',
  ];

  var body = [
    'Nossas soluções vão muito além da transação, com foco em melhorar a rentabilidade dos nossos clientes parceiros e experiência da sua carteira',
    'Our solutions go far beyond the transaction, with a focus on improving the profitability of our partner clients and their portfolio experience',
    'Nuestras soluciones van mucho más allá de la transacción, con un enfoque en mejorar la rentabilidad de nuestros clientes socios y la experiencia de su cartera',
  ];

  return (
    <Container ref={refConections}>
      <ClearFix>
        <div></div>
        <Content>
          <Title>
            {title[idioma]}
            <p>{subtitle[idioma]}</p>
          </Title>

          <Carousel>
            <FeaturesList>
              {features.map(({ icon, text, page }) => (
                <li
                  key={text}
                  className={`page${page} ${
                    page === activePage ? 'show' : 'hide'
                  }`}
                >
                  <img src={icon} alt={text} />
                  <p>{text[idioma]}</p>
                </li>
              ))}
            </FeaturesList>
            <Bullet>
              <li
                onClick={() => setActivePage(1)}
                className={activePage === 1 ? 'active' : ''}
              />
              <li
                onClick={() => setActivePage(2)}
                className={activePage === 2 ? 'active' : ''}
              />
            </Bullet>
          </Carousel>
        </Content>
      </ClearFix>
      <Footer fluid={sources}>
        <h2>
          {body[idioma]}
          <hr />
        </h2>
      </Footer>
    </Container>
  );
}
