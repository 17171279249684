import React, { useContext, useState, useEffect } from 'react';
import { LanguageContext } from '../../context/LanguageContext';
import LogoHubfintechBranco from '../../images/svg/Logo-hubfintech-branco.svg';
// import Idioma from '../Language/language';
import Menu from '../Menu';
import {
  Banner,
  ButtonNavigate,
  Container,
  Header,
} from './styles';

export default function Home({ scrollToBottom, scrollToForm }) {
  const { idioma } = useContext(LanguageContext);

  var title = [
    'Geramos soluções para o seu negócio.',
    'We provide solutions for your business.',
    'Generamos soluciones para su negocio.',
  ];
  var subtitle = [
    'mais que um banking as a service geramos melhores experiências para o seu negócio.',
    'More than banking as a service, we provide better experiences for your business.',
    'Más que un banking as a service, generamos mejores experiencias para su negocio.',
  ];
  var COVID = [
    `Nossa equipe está em casa, mas permanece trabalhando para garantir que os nossos serviços continuem operando com eficiência e qualidade.`,
    `Our team is at home, but remains working to ensure that our services continue to operate efficiently and with quality.`,
    `Nuestro equipo está en casa, pero sigue trabajando para garantizar que nuestros servicios continúen funcionando de manera eficiente y con calidad.`,
  ];

  return (
    <Container>
      <Header>
        <Banner>
          <p>
            <b>COVID-19: </b>
            {COVID[idioma]}
          </p>
        </Banner>
      </Header>
      <img src={LogoHubfintechBranco} />
      <h1>{title[idioma]}</h1>
      <p>{subtitle[idioma]}</p>
      <ButtonNavigate onClick={() => scrollToBottom()} />

      <Menu scrollToForm={scrollToForm} />
    </Container>
  );
}

/*        
//BANDEIRAS PARA TRADUÇÃO
        
*/
