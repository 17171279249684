import Parser from 'html-react-parser';
import React, { useContext, useState } from 'react';
import { LanguageContext } from '../../../context/LanguageContext';
import AnimacaoPlay from '../../../images/svg/icone-play-animacao.svg';
import tarjaMobile from '../../../images/svg/tarja-mobile.svg';
import tarja from '../../../images/svg/tarja.svg';
import Apis from './Apis';
import Carousel from './Carousel';
import { ChangeIcon, Container, PlayAnimation } from './styles';

export default function Play() {
  const { idioma } = useContext(LanguageContext);

  const [play, setPlay] = useState(false);

  const handlePlay = () => {
    setPlay(!play);
  };
  const [activeColor, setActiveColor] = useState('#ff3059');
  const [animationStart, setAnimationStart] = useState(true);
  const [flip, setFlip] = useState(false);

  const handleActiveColor = (color) => {
    setActiveColor(color);
    setAnimationStart(!animationStart);
  };
  const setAnimationEnd = () => setAnimationStart(false);

  const handleFlip = () => {
    setFlip(!flip);
  };

  var playLabel = [
    'Vamos dar um play?',
    'Shall we press play?',
    '¿Damos Play?',
  ];
  var title = [
    `<Title>Focamos no segmento <b>B2B2C</b> no modelo <b>white label</b> e nos<br />diferenciamos ao ofertarmos em um 
                <b>ecossistema</b> completo</Title>`,
    `<Title>We focus on the <b>B2B2C</b> segment in the white label model and<br/> differentiate ourselves by offering in a 
               complete <b>ecosystem</b>.</Title>`,
    `<Title>Nos centramos en el segmento <b>B2B2C</b> en el modelo white label y nos diferenciamos al ofrecer un 
               <b>ecosistema</b> completo.</Title>`,
  ];
  var ou = ['Ou', 'Or', 'O'];
  return (
    <Container>
      {play && (
        <>
          {Parser(title[idioma])}
          <section>
            <Apis activeColor={activeColor} flip={flip} />

            <ChangeIcon className="desktop" onClick={handleFlip}>
              <img src={tarja} alt="tarja reload" onClick={handleFlip} />
              <p onClick={handleFlip}>{ou[idioma]}</p>
            </ChangeIcon>

            <Carousel
              flip={flip}
              activeColor={activeColor}
              handleActiveColor={handleActiveColor}
              animationStart={animationStart}
              setAnimationEnd={setAnimationEnd}
            />
          </section>
          <ChangeIcon className="mobile" onClick={handleFlip}>
            <img src={tarjaMobile} alt="tarja reload" onClick={handleFlip} />
            <p onClick={handleFlip}>{ou[idioma]}</p>
          </ChangeIcon>
        </>
      )}
      <PlayAnimation play={play}>
        <div>
          <img
            onClick={() => handlePlay()}
            src={AnimacaoPlay}
            alt={playLabel[idioma]}
          />
          <p>{playLabel[idioma]}</p>
        </div>
      </PlayAnimation>
    </Container>
  );
}
