import Parser from 'html-react-parser';
import React, { useContext } from 'react';
import { LanguageContext } from '../../../../context/LanguageContext';
import CriacaoDeCartao from '../../../../images/svg/icone-criacaocartao.svg';
import CriacaoDeConta from '../../../../images/svg/icone-criacaoconta.svg';
import IconeIntegracao from '../../../../images/svg/icone-ligacao-api.svg';
import OutroServicos from '../../../../images/svg/icone-outrosservicos.svg';
import ServicosFinanceiros from '../../../../images/svg/icone-servicosfinanceiros.svg';
import {
  ButtonApiDeveloper,
  Container,
  Features,
  Integrations,
  Title,
} from './styles';

export default function Apis({ activeColor, flip }) {
  const { idioma } = useContext(LanguageContext);
  const apis = [
    [
      "Com mais de 1000 TPS's",
      'With more than 1000 TPSs',
      'Con más de 1000 TPS',
    ],
    ['CallBack', 'CallBack', 'CallBack'],
    ['Alta disponibilidade', 'High availability', 'Alta disponibilidad'],
    [
      'Gerenciamento através de dashboard',
      'Dashboard management',
      'Gestión a través de dashboard',
    ],
  ];
  const features = [
    {
      icon: CriacaoDeConta,
      text: ['Criação de contas', 'Account Creation', 'Creación de cuentas'],
    },
    {
      icon: CriacaoDeCartao,
      text: ['Criação de cartão', 'Card Creation', 'Creación de tarjeta'],
    },
    {
      icon: ServicosFinanceiros,
      text: [
        'Serviços financeiros',
        'Financial Services',
        'Servicios financieros',
      ],
    },
    {
      icon: OutroServicos,
      text: ['Outros serviços', 'Other Services', 'Otros servicios'],
    },
  ];

  var title = [
    'Você pode fazer tudo através do consumo de nossas <b>APIs</b>',
    'You can do everything by consuming our <b>APIs.</b>',
    'Puede hacer todo a través del consumo de nuestras <b>API.</b>',
  ];

  var subtitle = [
    '<p>APIs que integram as soluções ao seu negócio: </p>',
    '<p>APIs that integrate solutions to your business: </p>',
    '<p>API que integran las soluciones a su negocio: </p>',
  ];
  return (
    <Container flip={flip}>
      <Title>{Parser(title[idioma])}</Title>
      <Title>{Parser(subtitle[idioma])}</Title>

      <Integrations>
        {apis.map((api) => (
          <li key={api}>
            <img src={IconeIntegracao} alt="icone integração de api" />
            <p>{api[idioma]}</p>
          </li>
        ))}
      </Integrations>
      <Features>
        {features.map(({ icon, text }) => (
          <li key={text}>
            <img src={icon} alt={text} />
            <p>{text[idioma]}</p>
          </li>
        ))}
      </Features>
      <ButtonApiDeveloper
        bg={activeColor}
        onClick={() => window.open('http://docs.hubfintech.com.br/#/Glossary')}
      >
        API’s developer
      </ButtonApiDeveloper>
    </Container>
  );
}
