import { graphql, useStaticQuery } from 'gatsby';
import React, { useContext } from 'react';
import { LanguageContext } from '../../../../context/LanguageContext';
import { AnimationDiv } from '../../../Animate';
import { Bullet, Container, Indicator, Item, Title } from './styles';

export default function Carousel({
  activeColor,
  handleActiveColor,
  animationStart,
  flip,
  setAnimationEnd,
}) {
  const { idioma } = useContext(LanguageContext);

  const { red, yellow, green } = useStaticQuery(graphql`
    query {
      red: file(relativePath: { eq: "whitelabel-red.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 494) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      yellow: file(relativePath: { eq: "whitelabel-yellow.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 494) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      green: file(relativePath: { eq: "whitelabel-green.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 494) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  const colors = ['#ff3059', '#00b1b1', '#ff9d00'];

  const backgrounds = new Map();
  backgrounds.set('#ff3059', red.childImageSharp.fluid);
  backgrounds.set('#00b1b1', green.childImageSharp.fluid);
  backgrounds.set('#ff9d00', yellow.childImageSharp.fluid);

  const handleClassActiveColor = (color) =>
    activeColor === color ? 'active' : '';

  var title = [
    'Utilizar toda nossa estrutura de white label',
    'Use our entire white label structure.',
    'Utilice toda nuestra estructura de white label',
  ];
  return (
    <Container flip={flip}>
      <Title>{title[idioma]}</Title>
      <AnimationDiv
        className={animationStart ? 'start' : ''}
        direction="right"
        onAnimationEnd={() => setAnimationEnd()}
      >
        <Item fluid={backgrounds.get(activeColor)} loading="eager" />
      </AnimationDiv>
      <Indicator>
        {colors.map((color) => (
          <Bullet
            key={color}
            onClick={() => handleActiveColor(color)}
            className={handleClassActiveColor(color)}
            bg={color}
          />
        ))}
      </Indicator>
    </Container>
  );
}
