import styled from "styled-components"
import media from "styled-media-query"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;

  transition: transform 0.6s;
  transform-style: preserve-3d;
  ${media.lessThan("900px")`
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    transform: ${props => (props.flip ? "rotateY(180deg)" : "rotateY(0deg)")};
  `}
`

export const Title = styled.h2`
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 1.38;
  text-align: left;
  color: #ffffff;

  & p {
    font-size: 1.4rem;
    line-height: 2.71;
    text-align: left;
    font-weight: normal;
    color: #ffffff;
    margin-top: 0px;
  }
`

export const Integrations = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 2rem 0;

  & li {
    display: flex;
    align-items: center;

    p {
      font-size: 1.4rem;
      line-height: 2.71;
      font-weight: normal;
      text-align: left;
      color: #ffffff;
      white-space: nowrap;
    }
    img {
      max-width: 28px;
      margin-right: 24px;
    }
  }
  ${media.lessThan("900px")`
    
  `}
`

export const Features = styled.ul`
  display: flex;
  list-style: none;
  padding: 2rem 0;

  li {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

    & + li {
      margin-left: 60px;
    }
    p {
      width: 64px;
      font-size: 1.4rem;
      line-height: 1.36;
      text-align: center;
      color: #ffffff;
    }
    img {
      margin-bottom: 6px;
    }
  }
  ${media.lessThan("1060px")`
    li{
      & + li {
        margin-left: 30px;
      }
    }
  `}
  ${media.lessThan("900px")`
    width: 100%;
    justify-content: space-evenly;
    li{
        & + li {
          margin-left: 0px;
        }
      }
  `}
  ${media.lessThan("600px")`
      li{
        & img{
          width: 38px;
        }
      }
  `}
`

export const ButtonApiDeveloper = styled.button`
  margin: 4rem 0;
  width: fit-content;
  padding: 1.5rem 3rem;
  border-radius: 200px;
  background-color: #ffffff;
  color: ${props => props.bg};
  outline: none;
  cursor: pointer;

  font-size: 1.6rem;
  font-weight: bold;
  line-height: 1.3;
  text-align: center;
  color: ${props => props.bg};

  :active {
    opacity: 0.5;
  }
  ${media.lessThan("900px")`
    margin: 2rem auto;
  `}
`
