import HubPlug from "../../../images/svg/hub-plug.svg"
import HubPlugWhite from "../../../images/svg/hub-plug-white.svg"

import HubFinance from "../../../images/svg/hub-finance.svg"
import HubPrivate from "../../../images/svg/hub-private.svg"
import HubBeneficits from "../../../images/svg/hub-benefits.svg"
import HubGift from "../../../images/svg/hub-gift.svg"
import HubPaypaxx from "../../../images/svg/hub-paypaxx.svg"

import HubFinanceWhite from "../../../images/svg/hub-finance-white.svg"
import HubPrivateWhite from "../../../images/svg/hub-private-white.svg"
import HubBeneficitsWhite from "../../../images/svg/hub-benefits-white.svg"
import HubGiftWhite from "../../../images/svg/hub-gift-white.svg"
import HubPaypaxxWhite from "../../../images/svg/hub-paypaxx-white.svg"

export default [
  {
    icon: HubFinance,
    iconWhite: HubFinanceWhite,
    color: "#ffaaf9",
    content:
      [`<p>Soluções completas e customizáveis para a sua conta digital. Com conceito modular, você contrata as soluções financeiras que se adequam ao seu negócio e ainda é remunerado pela distribuição dos serviços</p>`,
       "<p>Complete and customizable solutions for your digital account. With a modular concept, you hire financial solutions that suit your business and are still paid for the distribution of services.</p>",
       "<p>Soluciones completas y personalizables para su cuenta digital. Con un concepto modular, usted contrata soluciones financieras que se adaptan a su negocio e, incluso, es remunerado por la distribución de servicios.</p>"],
    title: ["Amplo portfólio para incorporar as suas soluções", "Wide portfolio to incorporate your solutions", "Amplia cartera para incorporar sus soluciones</p>"],
  },
  {
    icon: HubPlug,
    iconWhite: HubPlugWhite,
    color: "#5ba7f8",
    content:
      ["<p>Com conceito White label, você utiliza toda nossa infraestrutura e define as regras do seu negócio. Esteja a frente dessa solução, com mais autonomia e rentabilidade para seu negócio.</p>",
       "<p>With the White label concept, you use all of our infrastructure and define the rules of your business. Be ahead of this solution, with more autonomy and profitability for your business.</p>",
       "<p>Con el concepto White label, utiliza toda nuestra infraestructura y define las reglas de su negocio. Esté al frente de esta solución, con más autonomía y rentabilidad para su negocio.</p>"],
    title: ["Uma nova forma de tratar adquirência", "A new way of dealing with acquisition", "Una nueva forma de lidiar con la adquirencia</p>"],
  },
  {
    icon: HubPrivate,
    iconWhite: HubPrivateWhite,
    color: "#00b1b1",
    content:
      ["<p>Transacione em sua loja sem precisar passar por adquirentes e outros intermediadores, transformando seu private label em conta digital, você potencializa a rentabilidade do seu negócio gerando aumento de vendas, fidelização da marca e redução de taxas.<p>",
       "<p>Transact in your store without having to go through buyers and other intermediaries; transforming your private label into a digital account, you maximize the profitability of your business by generating increased sales, brand loyalty and reduced fees.<p>",
       "<p>Realice transacciones en su tienda sin tener que pasar por compradores y otros intermediarios, transformando su private label en una cuenta digital, maximiza la rentabilidad de su negocio al generar mayores ventas, fidelización a la marca y tarifas reducidas.<p>"],
    title: ["Mais força para sua marca", "More strength to your brand", "Más fuerza para su marca"],
  },
  {
    icon: HubBeneficits,
    iconWhite: HubBeneficitsWhite,
    color: "#ff9d00",
    content:
       ["<p><b>Para empresas:</b> tenha seu próprio programa de alimentação e refeição, com aumento de receitas em um mercado que movimenta mais de 90bi ao ano.<br> <b>Para estabelecimentos:</b> faça parte da rede credenciada, e tenha seus créditos disponíveis no dia seguinte da transação.</p>",
        "<p><b>For companies:</b> have your own food and meal program, with increased revenues in a market that moves over 90 bi a year.<br> <b>For facilities:</b> be a part of the accredited network, and have your credits available the next day of the transaction.</p>",
        "<p><b>Para empresas:</b> tenga su propio programa de alimentación y comidas, con mayores ingresos en un mercado que mueve más de 90 mil millones al año.<br> <b>Para establecimientos:</b> sea parte de la red acreditada y tenga sus créditos disponibles al día siguiente de la transacción.</p>"],
    title: ["Uma nova forma de tratar o segmento de benefícios", "A new way of treating the benefits segment", "Una nueva forma de tratar el segmento de beneficios"],
  },
  {
    icon: HubGift,
    iconWhite: HubGiftWhite,
    color: "#ff3059",
    content:
      ["<p>Solução moderna e personalizada que intensifica relações e gera resultados. Uma grande alternativa para projetos especiais e que pode ser usada em todos os tipos de campanhas</p>",
       "<p>Modern and personalized solution that intensifies relationships and generates results. A great alternative for special projects that can be used in all types of campaigns.</p>",
       "<p>Solución moderna y personalizada que intensifica las relaciones y genera resultados. Una gran alternativa para proyectos especiales que se pueden utilizar en todo tipo de campañas.</p>"],
    title: ["Vantagens para quem recebe e para quem presenteia", "Advantages for the recipient and the giver", "Ventajas para quien recibe y para quien regala"],
  },
  {
    icon: HubPaypaxx,
    iconWhite: HubPaypaxxWhite,
    color: "#002a6a",
    content:
      ["<p>Controle suas despesas de forma ágil, sem burocracia e longos prazos com processos de reembolso. É mais flexibilidade e controle financeiro para a sua empresa.</p>",
       "<p>Control your expenses in an agile way, without bureaucracy and long terms with reimbursement processes. More flexibility and financial control for your company.</p>",
       "<p>Controle sus gastos de forma ágil, sin burocracia y a largo plazo con procesos de reembolso. Es más flexibilidad y control financiero para su empresa.</p>"],
    title: ["A reinvenção dos cartões corporativos", "Reinvention of corporate cards", "La reinvención de las tarjetas corporativas"],
  },
]
