import styled from "styled-components"
import Img from "gatsby-image"
import media from "styled-media-query"

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;

  transition: transform 0.6s;
  transform-style: preserve-3d;

  ${media.lessThan("900px")`
    position: absolute;
    width: 100%;
    height: 80%;
    backface-visibility: hidden;
    transform: ${props => (!props.flip ? "rotateY(180deg)" : "rotateY(0deg)")};
  `}
`

export const Title = styled.h2`
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 1.38;
  text-align: left;
  color: #ffffff;
`

export const Item = styled(Img)`
  width: 100%;
  height: 100%;
  min-width: 494px;
  flex: 1;
  & img {
    object-fit: contain !important;
  }
  ${media.lessThan("975px")`
     min-width: 350px; 
  `}
`

export const Indicator = styled.ul`
  list-style: none;
  display: flex;
  z-index: 9;

  ${media.lessThan("600px")`
    flex-direction: column;
    align-items: center;
    position: absolute;
    right: 0;
    top: 50%;
  `}
`

export const Bullet = styled.li`
  width: 40px;
  height: 10px;
  border-radius: 100px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 0.8px #ffffff;
  background-color: ${props => props.bg};
  cursor: pointer;
  transition: 0.75s ease-in-out;

  &.active {
    border: solid 2px #ffffff;
  }
  & + li {
    margin-left: 20px;
  }
  ${media.lessThan("600px")`
    width: 10px;
    height: 40px;
     & + li {
      margin-top:20px;
      margin-left: 0px;
     } 
  `}
`
