import styled from "styled-components"
import media from "styled-media-query"
export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  overflow: hidden;
`
export const Content = styled.div`
  display: flex;
  margin: 2rem;
  width: 100%;
  flex: 1;
  padding: 6.2rem 10rem;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;

  ${media.lessThan("1300px")`
    padding: 4rem 6rem;
    flex-direction: column;
  `}
  ${media.lessThan("600px")`
    padding: 4rem ;
  `}
`
export const Text = styled.div`
  display: block;
  max-width: 590px;
  flex: 1;

  h1 {
    font-size: 2.4rem;
    line-height: 2.04;
    text-align: left;
    font-weight: normal;
    color: #ff9d00;

    max-width: 42rem;
    hr {
      width: 90px;
      height: 6px;
      border-radius: 100px;
      background-color: #ff9d00;
      margin: 20px 0;
    }
  }
  ${media.lessThan("1300px")`
    padding: 0;
    margin-bottom: 2rem;
    h1{
      max-width: 100%;
    }
  `}
  ${media.lessThan("600px")`
  `}
`
