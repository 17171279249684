import styled, { keyframes } from "styled-components"
import media from "styled-media-query"

const AnimateLeft = keyframes`
  0% {
    left: 0;
  }
  50%{
    left: -100%;
  }
  100%{
    left: 0;
  }
`

const AnimateRight = keyframes`
  0% {
    right: 0;
  }
  50%{
    right: -100%;
  }
  100%{
    right: 0;
  }
`
const AnimateTop = keyframes`
  0% {
    top: 0;
  }
  50%{
    top: -100%;
  }
  100%{
    top: 0;
  }
`

const AnimateBottom = keyframes`
  0% {
    bottom: 0;
  }
  50%{
    bottom: -100%;
  }
  100%{
    bottom: 0;
  }
`
export const AnimationDiv = styled.div`
  position: relative;

  &.start {
    animation: 1.5s infinite;
    animation-direction: right;
    animation-name: ${props =>
      props.direction === "left" ? AnimateLeft : AnimateRight};
    animation-iteration-count: 1;

    ${media.lessThan("600px")`
      animation-name: ${props =>
        props.direction === "left" ? AnimateBottom : AnimateTop};
    `}
  }
`
