import styled from "styled-components"
import media from "styled-media-query"

export const CustomScrollbar = styled.div`
  width: 100%;
  float: left;
  height: 200px;
  margin-left: 15px;
  margin-top: 40px;
  overflow-x: scroll;
  overflow-y: hidden;
  background: transparent;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    height: 4px;
    border-radius: 50px;
    background-color: #fff;
  }
  &::-webkit-scrollbar-thumb {
    cursor: pointer;
    background-color: #ddd;
    transition: background 0.2s;
    &:hover {
      background-color: #bbb;
    }
  }
  &::-webkit-scrollbar-track {
    background-color: #fff;
  }
`
export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: 6.2rem 20rem;
  position: relative;
  overflow: hidden;
  background-color: #fff;
  transition: background-color 1s linear;
  ${media.lessThan("1280px")`
    padding: 3rem 6rem;
  `}
  ${media.lessThan("600px")`
    padding: 3rem ;
  `}
`

export const Title = styled.h1`
  font-size: 4.4rem;
  font-weight: bold;
  line-height: 1.36;
  text-align: left;
  color: #002a6a;
  width: 100%;
  display: flex;
  align-items: center;

  hr {
    width: 6px;
    height: 55px;
    border-radius: 100px;
    background-color: #002a6a;
    margin: 30px;
  }
  p {
    width: 100%;
    max-width: 438px;
    font-size: 2rem;
    line-height: 1.7;
    text-align: left;
    color: #707070;
    font-weight: normal;
  }
  ${media.lessThan("1280px")`
    font-size: 3.6rem;
    p{
      font-size: 1.6rem;
    }
  `}
  ${media.lessThan("925px")`
    flex-direction: column;
    hr{
      display: none;
    }    
    p{
      max-width: 100%;
      text-align: center;
      font-size: 1.4rem;
    }
  `}
  ${media.lessThan("600px")`
    text-align: center;
  `}
`
export const ListSolutions = styled.ul`
  list-style: none;
  display: flex;
  width: 100%;
  flex: 1;
  justify-content: center;
  padding: 0 2rem;
  ${media.lessThan("1280px")`
    justify-content: space-between;
  `}
`

export const SolutionItem = styled.li`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 174px;
  min-width: 174px;
  max-height: 174px;
  min-height: 174px;
  border-radius: 20px;
  border: solid 1px;
  border-color: ${props => props.bg};
  padding: 2rem;
  font-size: 2rem;
  line-height: 1.75;
  text-align: center;
  color: ${props => props.bg};
  & + li {
    margin-left: 40px;
  }
  & img {
    width: auto;
    max-height: 20px;
    height: 100%;
  }
  & :hover {
    box-shadow: 0 14px 20px 0 rgba(0, 0, 0, 0.16);
    cursor: pointer;
  }
  &.active {
    background-color: ${props => props.bg};
    color: #fff;
    box-shadow: 0 14px 20px 0 rgba(0, 0, 0, 0.16);
  }
  ${media.lessThan("1280px")`
    max-width: 164px;
    max-height: 162px;
    min-width: 164px;
    min-height: 162px;
    & + li {
      margin-left: 25px;
    }
  `}
`
export const Content = styled.section`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding-bottom: 6rem;

  & > div:first-child {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    & img {
      width: auto;
      height: 40px;
    }
  }
  ${media.lessThan("901px")`
    & > div:first-child {
      & img {
        display:none
      }
    }
  `}

  & > div:last-child {
    flex: 2;
    display: flex;
    justify-content: center;
  }

  ${media.lessThan("900px")`
    flex-direction: column;
      & > div:first-child {
        & img {
          margin-top: 1rem;
        }
      }
  `}
  ${media.between("901px", "1280px")`
      margin-top: -30px;
  `}
`
export const Text = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4rem 2rem;
  transition: 1s linear;

  & > h1 {
    font-size: 2.8rem;
    font-weight: 600;
    line-height: 1.64;
    text-align: left;
    color: ${props => props.bg};
  }
  & > p {
    font-size: 2rem;
    line-height: 1.6;
    text-align: left;
    color: #707070;
    margin-top: 28px;
    flex: 1;
  }
  & > div {
    align-self: flex-end;
    justify-self: flex-end;
    display: flex;
    align-items: center;
    margin-top: 2rem;
    cursor: pointer;

    p {
      margin-left: 15px;
      font-size: 2.5rem;
      line-height: 1.36;
      text-align: right;
      color: ${props => props.bg};
      text-transform: uppercase;
    }
    & :active {
      opacity: 0.5;
    }
  }
  ${media.lessThan("1280px")`
    & h1 {
      font-size: 2.4rem;
    }
    & > p {
      font-size: 1.6rem;
    }
    & > div {
      p{
        font-size:1.6rem;
      }
    }
  `}

  ${media.lessThan("600px")`
    padding: 2rem 0;
    h1{
      text-align: center;
    }
  `}
`
export const ButtonOurSolutions = styled.button`
  width: fit-content;
  padding: 1.5rem 3rem;
  border-radius: 200px;
  background-color: #00b1b1;
  outline: none;
  cursor: pointer;

  font-size: 1.6rem;
  font-weight: bold;
  line-height: 1.38;
  text-align: center;
  color: #ffffff;

  :active {
    opacity: 0.5;
  }
  ${media.lessThan("1280px")`
    font-size: 1.4rem;
  `}
`
