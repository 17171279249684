import React, { useContext } from 'react';
import { LanguageContext } from '../../../context/LanguageContext';
import Form from '../../FormContact';
import { Container, Content, Text } from './styles';

export default function Contact({ formRef }) {
  const { idioma } = useContext(LanguageContext);

  var title = [
    'Encontre na Hub Fintech a solução completa para alavancar seus negócios no mercado financeiro',
    'Find the complete solution to leverage your business in the financial market at Hub Fintech',
    'Encuentre en Hub Fintech la solución completa para aprovechar su negocio en el mercado financiero',
  ];
  return (
    <Container>
      <Content ref={formRef}>
        <Text>
          <h1>
            {title[idioma]}
            <hr />
          </h1>
        </Text>
        <Form />
      </Content>
    </Container>
  );
}
